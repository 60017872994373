import React from "react";

const Input = ({
  label,
  type,
  name,
  value,
  onChange,
  onBlur,
  options = [],
  min,
  error,
}) => {
  let inputElement = null;
  const inputClassName = `input-field border px-2 py-3 rounded-md ${
    error ? "border-red-500" : "border-gray-400"
  }`;

  switch (type) {
    case "textarea":
      inputElement = (
        <textarea
          name={name}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          className={inputClassName}
        />
      );
      break;
    case "select":
      inputElement = (
        <select
          name={name}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          className={inputClassName}
        >
          <option value="" className="text-gray-400">
            Select an option
          </option>
          {options.map((option, index) => (
            <option key={index} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      );
      break;
    case "file":
      inputElement = (
        <input
          type={type}
          name={name}
          accept="video/*"
          onChange={onChange}
          onBlur={onBlur}
          className={inputClassName}
        />
      );
      break;
    default:
      inputElement = (
        <input
          type={type}
          name={name}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          className={inputClassName}
          min={min}
        />
      );
  }

  return (
    <div className="flex flex-col gap-1 w-full">
      <label className={`input-field text-sm ${error ? "text-red-500" : "text-gray-600"}`}>
        {label}
      </label>
      {inputElement}
      {error && <p className="text-red-500 text-xs mt-1">{error}</p>}
    </div>
  );
};

export default Input;
