import React from 'react'
import Navbar from '../components/Navbar'

const PrivacyPolicy = () => {
  return (
    <div className="">
      <Navbar />
      <div className="px-8 md:px-12 privacy-policy flex flex-col gap-8 mt-8 text-base pb-8">
        <h2 className="text-xl font-bold font-nueu ">Privacy Policy</h2>

        <p>
          SME Scale-up is committed to protecting your privacy. 
          This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website 
          and register for our services.
        </p>

        <h3 className="text-lg font-semibold mt-4">1. Information We Collect</h3>
        <p>
          When you register on our website, we may collect the following types of information:
        </p>
        <ul className="list-disc pl-8">
          <li>Full Name</li>
          <li>Email Address</li>
          <li>Phone Number</li>
          <li>Business Name</li>
          <li>Business Address</li>
          <li>Business Industry</li>
          <li>Other relevant business details</li>
        </ul>

        <h3 className="text-lg font-semibold mt-4">2. How We Use Your Information</h3>
        <p>We use the information collected for the following purposes:</p>
        <ul className="list-disc pl-8">
          <li>To register you and provide you access to SME Scale-up resources</li>
          <li>To communicate with you regarding events, programs, or updates related to SME Scale-up</li>
          <li>To improve our services and user experience on the platform</li>
          <li>To comply with legal and regulatory obligations</li>
        </ul>

        <h3 className="text-lg font-semibold mt-4">3. Cookies and Tracking Technologies</h3>
        <p>
          When you visit our website, we use cookies to enhance your experience and improve our services. 
          Cookies are small files stored on your device that help us understand how visitors interact with our website.
        </p>
        <p>We use the following types of cookies:</p>
        <ul className="list-disc pl-8">
          <li><strong>Strictly Necessary Cookies:</strong> Essential for website functionality.</li>
          <li><strong>Performance Cookies:</strong> Help us analyze site usage and improve performance.</li>
          <li><strong>Functionality Cookies:</strong> Remember your preferences and settings.</li>
        </ul>
        <p>
          You can manage or disable cookies in your browser settings. However, some parts of the website may not function properly without cookies.
        </p>

        <h3 className="text-lg font-semibold mt-4">4. Data Protection and Security</h3>
        <p>
          We implement appropriate security measures to safeguard your personal data from unauthorized access, 
          alteration, or disclosure. However, no method of transmission over the Internet is 100% secure, and 
          we cannot guarantee absolute security.
        </p>

        <h3 className="text-lg font-semibold mt-4">5. Your Rights and Choices</h3>
        <p>
          As a user, you have the following rights regarding your personal data:
        </p>
        <ul className="list-disc pl-8">
          <li>The right to access, update, or delete your personal information.</li>
          <li>The right to withdraw consent for data processing (where applicable).</li>
          <li>The right to request a copy of the information we hold about you.</li>
          <li>The right to restrict or object to data processing.</li>
        </ul>
        <p>
          To exercise any of these rights, please contact us using the contact details provided on our website.
        </p>

        <h3 className="text-lg font-semibold mt-4">6. Updates to This Privacy Policy</h3>
        <p>
          We may update this Privacy Policy from time to time to reflect changes in our services or legal requirements. 
          We will notify users of significant updates by posting the revised policy on our website.
        </p>

      </div>
    </div>
  )
}

export default PrivacyPolicy
