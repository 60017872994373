import React, { useState, useEffect } from "react";
import CookieConsent, { getCookieConsentValue, resetCookieConsentValue } from "react-cookie-consent";

const CookieBanner = () => {
  const [showSettings, setShowSettings] = useState(false);

  useEffect(() => {
    if (!getCookieConsentValue("SMEScaleUpCookie")) {
      setShowSettings(true);
    }
  }, []);

  return (
    <div>
      <CookieConsent
        location="bottom"
        buttonText="I Understand"
        cookieName="SMEScaleUpCookie"
        style={{
          background: "#025159",
          color: "#fff",
          padding: "16px",
          textAlign: "center",
        }}
        buttonStyle={{
          background: "#F8E71C",
          color: "#000",
          fontSize: "14px",
          fontWeight: "bold",
          borderRadius: "5px",
          padding: "10px 20px",
        }}
        declineButtonStyle={{
          background: "#FF4C4C",
          color: "#fff",
          fontSize: "14px",
          fontWeight: "bold",
          borderRadius: "5px",
          padding: "10px 20px",
        }}
        expires={150}
        onAccept={() => {
          console.log("User accepted cookies");
          setShowSettings(false);
        }}
        onDecline={() => {
          console.log("User declined cookies");
        }}
      >
        <p className="text-sm">
          This website uses cookies to enhance your experience and improve our services. 
          By accepting, you agree to our <a href="/privacy-policy" style={{ color: "#F8E71C" }}>Privacy Policy</a>.
        </p>
      </CookieConsent>

      {/* Cookie Settings Button */}
      {/* {showSettings && (
        <button
          onClick={() => resetCookieConsentValue()}
          className="fixed z-20 bottom-4 right-4 bg-blue-500 text-white px-4 py-2 rounded-md shadow-md hover:bg-blue-600"
        >
          Cookie Settings
        </button>
      )} */}
    </div>
  );
};

export default CookieBanner;
