import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import FormContext from "../FormContext";
import Input from "./Input";
import StepIndicator from "./StepIndicator";

const Step2 = ({ nextStep, prevStep }) => {
  const { formData, setFormData, data } = React.useContext(FormContext);

  Yup.addMethod(Yup.string, "maxWords", function (max, message) {
    return this.test("maxWords", message, function (value) {
      const { path, createError } = this;
  
      if (!value || typeof value !== "string") return true; // Allow empty values
  
      // Normalize whitespace, remove empty entries and count words correctly
      const wordCount = value.trim().split(/\s+/).filter(word => word.length > 0).length;
  
      console.log("Current Word Count:", wordCount, "Max:", max);
  
      return wordCount <= max ? true : createError({ path, message });
    });
  });
  


  // Define validation schema with Yup
  const validationSchema = Yup.object({
    businessName: Yup.string()
      .required("Business Name is required")
      .min(2, "Business Name must be at least 2 characters")
      .max(50, "Business Name cannot exceed 50 characters"),
    
    cacNumber: Yup.string()
      .required("CAC Number is required"),
    numberOfEmployees: Yup.string()
      .required("Number of Employees is required"),
    activeYears: Yup.string()
      .required("Active Years in Business is required"),
    annualTurnover: Yup.string()
      .required("Annual Turnover is required"),
    sector: Yup.string()
      .required("Business Sector is required"),
    position: Yup.string()
      .required("Current Business Position is required"),
    
    businessDescription: Yup.string()
      .required("Business Description is required")
      .maxWords(50, "Business Description should not exceed 50 words")
      .test("no-profanity", "Business Description contains invalid words", (value) => {
        const prohibitedWords = ["fraud", "scam", "illegal"];
        return !prohibitedWords.some((word) => value?.toLowerCase().includes(word));
      }),
  });
  

  const formik = useFormik({
    initialValues: formData,
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (values) => {
      setFormData(values);
      nextStep(); // Move to the next step
    },
  });

  // Handle the next button click
  const handleNextClick = async (event) => {
    event.preventDefault(); 
    const errors = await formik.validateForm();
    
    formik.setTouched({
      businessName: true,
      cacNumber: true,
      numberOfEmployees: true,
      activeYears: true,
      annualTurnover: true,
      sector: true,
      position: true,
      businessDescription: true,
    });
  
    if (Object.keys(errors).length === 0) {
      formik.handleSubmit(); 
    } else {
      console.log("Validation Errors:", errors); 
    }
  };
  
  return (
    <form onSubmit={formik.handleSubmit} className="step-2-container">
      <div className="top">
        <StepIndicator currentStep={1} />
      </div>
      <div className="flex flex-col justify-center items-center">
        <h1 className="text-secondary-color font-nueu text-xl">
          Business Information
        </h1>
        <p className="text-sm text-slate-700">
          Please enter your business information
        </p>
      </div>
      <div className="form-content flex flex-col gap-5 mt-4 w-11/12">
        <div className="flex flex-col md:flex-row justify-between gap-4 md:gap-8 w-full">
          <Input
            label="Business Name"
            type="text"
            name="businessName"
            value={formik.values.businessName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.businessName && formik.errors.businessName}
          />
          <Input
            label="CAC Number"
            type="text"
            name="cacNumber"
            value={formik.values.cacNumber}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.cacNumber && formik.errors.cacNumber}
          />
        </div>
        <div className="flex flex-col md:flex-row justify-between gap-4 md:gap-8 w-full">
          <Input
            label="Number of Employees"
            type="select"
            name="numberOfEmployees"
            value={formik.values.numberOfEmployees}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.numberOfEmployees && formik.errors.numberOfEmployees}
            options={
              data && data.numberOfEmployees
                ? data.numberOfEmployees.map((item) => ({
                    value: item.code,
                    label: item.description,
                  }))
                : []
            }
          />
          <Input
            label="Active Years in Business"
            type="select"
            name="activeYears"
            value={formik.values.activeYears}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.activeYears && formik.errors.activeYears}
            options={
              data && data.yearsOfOperation
                ? data.yearsOfOperation.map((item) => ({
                    value: item.code,
                    label: item.description,
                  }))
                : []
            }
          />
        </div>
        <div className="flex flex-col md:flex-row justify-between gap-4 md:gap-8 w-full">
          <Input
            label="Business Annual Turnover"
            type="select"
            name="annualTurnover"
            value={formik.values.annualTurnover}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.annualTurnover && formik.errors.annualTurnover}
            options={
              data && data.annualTunover
                ? data.annualTunover.map((item) => ({
                    value: item.code,
                    label: item.description,
                  }))
                : []
            }
          />
          <Input
            label="Business Sector"
            type="select"
            name="sector"
            value={formik.values.sector}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.sector && formik.errors.sector}
            options={[
              { value: "agriculture", label: "Agriculture" },
              { value: "manufacturing", label: "Manufacturing" },
              { value: "tech", label: "Tech" },
              { value: "education", label: "Education" },
              { value: "health", label: "Health" },
              { value: "finance", label: "Finance" },
              { value: "other", label: "Other" },
            ]}
          />
        </div>
        <div className="flex flex-col md:flex-row justify-between gap-4 md:gap-8 w-full">
          <Input
            label="Current Business Position (e.g CEO, Founder)"
            type="select"
            name="position"
            value={formik.values.position}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.position && formik.errors.position}
            options={[
              { value: "founder/co-founder", label: "Founder/Co-Founder" },
              { value: "ceo/md", label: "CEO/MD" },
              { value: "cfo", label: "CFO" },
              { value: "cto", label: "CTO" },
              { value: "cmo", label: "CMO" },
              { value: "manager/team-lead", label: "Manager/Team Lead" },
              { value: "other", label: "Other" },
            ]}
          />
        </div>
        <div className="flex flex-col md:flex-row justify-between gap-4 md:gap-8 w-full">
          <Input
            label="Describe your business in 50 words or less"
            type="textarea"
            name="businessDescription"
            value={formik.values.businessDescription}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.businessDescription && formik.errors.businessDescription}
          />
        </div>
      </div>

      <div className="button-container">
        <div className="flex gap-4 w-full">
          <button type="button" onClick={prevStep} className="prev-btn font-nueu ">
            Prev
          </button>
          <button type="button" onClick={handleNextClick} className="next-btn font-nueu ">
            Next
          </button>
        </div>
      </div>
    </form>
  );
};

export default Step2;
