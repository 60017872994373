import React, {useRef} from "react";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
const About = () => {

    const ref1 = useRef(null);
    const ref2 = useRef(null);
    const ref3 = useRef(null);
    const ref4 = useRef(null);

    useGSAP(() => {
        gsap.registerPlugin(ScrollTrigger);
        gsap.from(ref1.current, {
            x: -100,
            opacity: 0,
            duration: 1,
            ease: "in-out",
            scrollTrigger: {
                trigger: ref1.current,
                start: "top 80%",
                end: "top 50%",
                toggleActions: "play none none reverse",
            },
        });
        gsap.from(ref2.current, {
            x: 100,
            opacity: 0,
            duration: 1,
            ease: "in-out",
            scrollTrigger: {
                trigger: ref2.current,
                start: "top 80%",
                end: "top 50%",
                toggleActions: "play none none reverse",
            },
        });
        gsap.from(ref3.current, {
            x: -100,
            opacity: 0,
            duration: 1,
            ease: "in-out",
            scrollTrigger: {
                trigger: ref3.current,
                start: "top 80%",
                end: "top 50%",
                toggleActions: "play none none reverse",
            },
        });
        gsap.from(ref4.current, {
            x: 100,
            opacity: 0,
            duration: 1,
            ease: "in-out",
            scrollTrigger: {
                trigger: ref4.current,
                start: "top 80%",
                end: "top 50%",
                toggleActions: "play none none reverse",
            },
        });
      }, []);



  


  return (
    <div
      className="relative container px-3 md:px-8 py-5  flex flex-col min-h-screen justify-center items-center about"
      id="about"
    >
      <div className="flex flex-col w-full md:w-11/12 mx-auto  h-full mt-4 md:mt-32 gap-8">
        <div className="flex flex-col md:flex-col lg:flex-row gap-12">
          <div className="about-box-1 " id="box-2" ref={ref1} ></div>
          <div className="flex-1 z-10 md:px-8" id="text-1" ref={ref2}>
            <h1 className="text-5xl text-secondary-color font-nueu ">
              About
              <br />
              <span className="text-primary-color">SME Scale up</span>
            </h1>
            <p className="text-secondary-color font-sans text-base mt-4 leading-loose text-balance">
              The SME SCALE UP is a platform targeted at SME CEOs in Nigeria who
              want to scale up and expand their businesses. The objective is to
              reduce the rate at which small businesses in Nigeria fail and
              decrease the rate of unemployment in Nigeria.
            </p>
            <p className="text-secondary-color font-sans text-base mt-4 leading-loose">
              With a vision to be a leading catalyst for scaling SMEs, fostering
              a thriving entrepreneurial ecosystem, and drive sustainable
              employment for 1 million Nigerians by 2035.
            </p>
            <p className="text-secondary-color font-sans text-base mt-4 leading-loose">
              We are set to equip SMEs with the knowledge, skills, and network
              they need to overcome growth barriers, scale their businesses
              sustainably, and create lasting employment opportunities in
              Nigeria.
            </p>
          </div>
        </div>
        <div className="flex flex-col-reverse 2xl:flex-row gap-2 mt-4">
          <div className="flex-1">
            <h1 className="text-5xl text-secondary-color font-nueu m-0 ">
              Program <span className="text-primary-color">Details</span>
            </h1>

            <div className="flex flex-col gap-2 mt-4" id="text-2" ref={ref3}>
             
              {/* more about the program text */}
              <p className="text-secondary-color font-sans text-base font-bold leading-loose text-balance">
                The workshop covers a range of topics including:
              </p>
              <ul className="text-secondary-color font-sans text-base mt-2 leading-loose" id="list">
                <li>Leadership and Management</li>
                <li>Business Strategy</li>
                <li>Financial Management</li>
                <li>Marketing and Sales</li>
                <li>Human Resources</li>
                <li>Operations Management</li>
                <li>Business Ethics</li>
              </ul>
            </div>
          </div>
          <div className=" about-box-2" id="box-2" ref={ref4}></div>
        </div>
      </div>
    </div>
  );
};

export default About;
